import(/* webpackMode: "eager" */ "/app/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/layout/NavBar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/components/ui/sonner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"lib/fonts.ts\",\"import\":\"Roboto\",\"arguments\":[{\"weight\":[\"300\"],\"subsets\":[\"latin\"]}],\"variableName\":\"RobotoFont\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"lib/fonts.ts\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"InterFont\"}");
